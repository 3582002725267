<template>
    <div class="col-6 col-xs-12 col-md-6">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="resetSearch"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="documentfooters"
                        table-id="documentFootersTable"
                        style="padding-top: 2px !important;"
                        @add-action="editDialog = true"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="CountriesListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.documentFooters"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allDocumentFooters"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            :sort-by="['type','id']"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="documentFootersTable"
            item-key="item_id"
        >
            <template v-slot:item.text="{ item }">
                <span>{{ item.text }}</span>
            </template>
            <template v-slot:item.sort_id="{ item }">
                <div class="d-flex flex-column align-center">
                    <div class="sort-id mb-1" @click="openEditDocumentFooterSortDialog(item.item_id, item.text, item.type)" v-if="item.sort_id != null">
                        {{ item.sort_id }}
                    </div>
                    <div class="sort-id mb-1 red--text text--darken-1" @click="openEditDocumentFooterSortDialog(item.item_id, item.text, item.type)" v-else>
                        {{ '?' }}
                    </div>
                </div>
            </template>
            <template v-slot:item.type="{ item }">
                <span class="font-weight-bold">{{ capitalize(item.type) }}</span>
            </template>
            <template v-slot:item.item_id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditDocumentFooterDialog(item.item_id, item.text)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDocumentFooter') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteDocumentFooter(item.item_id, item.text)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteDocumentFooter') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <AddDocumentFooter
            :document-footer="selectedDocumentFooter"
            :document-footer-id="selectedDocumentFooterId"
            :dialog.sync="editDialog"
            :page-key="pageKey"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @create-done="documentFooterCreated"
            @update-done="documentFooterUpdated"
        ></AddDocumentFooter>
        <UpdateDocumentFooterSorting
            :dialog.sync="editSortDialog"
            :document-footer-id="selectedDocumentFooterId"
            :document-footer="selectedDocumentFooter"
            :document-footer-type="selectedDocumentFooterType"
            :page-key="pageSortKey"
            :update-mode="updateSortMode"
            @dialog-closed="editSortDialogClosed"
            @update-done="documentFooterSortingUpdated"
        ></UpdateDocumentFooterSorting>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {capitalize} from "Helpers/helpers";

const AddDocumentFooter = () => import("Components/Appic/AddDocumentFooter");
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");
const UpdateDocumentFooterSorting = () => import("Components/Appic/UpdateDocumentFooterSorting");

export default {
    name: "DocumentFootersList",
    components: {UpdateDocumentFooterSorting, HelpPopUpV2, AddDocumentFooter, ExportTable},
    props:  ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: false,
            editSortDialog: false,
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                documentFooters: false
            },
            searchField: null,
            searchTerm: null,
            selectedDocumentFooter: null,
            selectedDocumentFooterId: null,
            selectedDocumentFooterType: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalDocumentFooters: 0,
            updateMode: false,
            updateSortMode: false
        }
    },
    computed: {
        ...mapGetters('documentfooter',{
            allDocumentFooters: 'allDocumentFooters'
        }),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'item_id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.document'),
                    value: 'type',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.id'),
                    value: 'id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.footerText'),
                    value: 'text',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.sortId'),
                    value: 'sort_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
            ]
        },
        pageKey() {
            return Math.floor(Math.random() * 100)
        },
        pageSortKey() {
            return Math.floor(Math.random() * 100)
        }
    },
    methods: {
        ...mapActions('documentfooter',{
            deleteDocumentFooterById: 'deleteDocumentFooterById',
            getAllDocumentFooters: 'getAllDocumentFooters',
            resetState: 'resetState'
        }),
        capitalize,
        documentFooterCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        documentFooterSortingUpdated() {
            this.editSortDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        documentFooterUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        async deleteDocumentFooter(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteDocumentFooter') + ' ' +  title, this.$t('message.confirmations.continueDocumentFooterCancelAction'), {color: 'orange'})){
                this.deleteDocumentFooterById(val)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.documentFooterDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetState()
                            this.getAllDocumentFooters()
                        } else {
                            this.$toast.error(this.$t('message.errors.documentFooterNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch((err) => {
                        this.$toast.error(err,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        editSortDialogClosed () {
            this.editSortDialog = false;
            if(this.updateSortMode == false){
                this.$emit('edit-sort-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateDocumentFooterDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditDocumentFooterDialog (documentFooterId, documentFooter) {
            this.selectedDocumentFooterId = documentFooterId
            this.selectedDocumentFooter = documentFooter
            this.updateMode = true
            this.editDialog = true
        },
        openEditDocumentFooterSortDialog(documentFooterId,documentFooter, documentFooterType ) {
            this.selectedDocumentFooter = documentFooter
            this.selectedDocumentFooterId = documentFooterId
            this.selectedDocumentFooterType = documentFooterType
            this.updateSortMode = true
            this.editSortDialog = true
        },
        resetSearch() {
            this.searchTerm = null
            this.getAllDocumentFooters()
        }
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateDocumentFooterDialog ()
            }
        }
    },
    created() {
        this.resetState()
        if(this.allDocumentFooters.length == 0) this.getAllDocumentFooters()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.sort-id {
    cursor: pointer;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: lightgray;
    display: inline-flex !important;
    align-items: center;
    vertical-align: middle;
    flex-direction: column !important;
    justify-content: center;
    position: relative;
}
</style>