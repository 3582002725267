var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6 col-xs-12 col-md-6"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.resetSearch}},[_c('v-icon',[_vm._v("refresh")])],1),_c('ExportTable',{staticClass:"mt-0 mr-0",staticStyle:{"padding-top":"2px !important"},attrs:{"has-add-function":true,"cols-to-delete":_vm.colsToDelete,"file-name":"documentfooters","table-id":"documentFootersTable"},on:{"add-action":function($event){_vm.editDialog = true}}}),_c('HelpPopUpV2',{attrs:{"help-page-component":"CountriesListing","no-padding":true}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.documentFooters,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"footer-props":{
            itemsPerPageOptions: [20,50,-1],
            showCurrentPage: true,
            showFirstLastPage: true
        },"headers":_vm.headers,"height":_vm.tableHeight,"items":_vm.allDocumentFooters,"items-per-page":-1,"search":_vm.searchTerm,"options":_vm.tableOptions,"sort-by":['type','id'],"calculate-widths":"","dense":"","fixed-header":"","id":"documentFootersTable","item-key":"item_id"},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.text",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.text))])]}},{key:"item.sort_id",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[(item.sort_id != null)?_c('div',{staticClass:"sort-id mb-1",on:{"click":function($event){return _vm.openEditDocumentFooterSortDialog(item.item_id, item.text, item.type)}}},[_vm._v(" "+_vm._s(item.sort_id)+" ")]):_c('div',{staticClass:"sort-id mb-1 red--text text--darken-1",on:{"click":function($event){return _vm.openEditDocumentFooterSortDialog(item.item_id, item.text, item.type)}}},[_vm._v(" "+_vm._s('?')+" ")])])]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.capitalize(item.type)))])]}},{key:"item.item_id",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditDocumentFooterDialog(item.item_id, item.text)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateDocumentFooter'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteDocumentFooter(item.item_id, item.text)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteDocumentFooter')))],1)],1)],1)],1)]}}])}),_c('AddDocumentFooter',{attrs:{"document-footer":_vm.selectedDocumentFooter,"document-footer-id":_vm.selectedDocumentFooterId,"dialog":_vm.editDialog,"page-key":_vm.pageKey,"update-mode":_vm.updateMode},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"create-done":_vm.documentFooterCreated,"update-done":_vm.documentFooterUpdated}}),_c('UpdateDocumentFooterSorting',{attrs:{"dialog":_vm.editSortDialog,"document-footer-id":_vm.selectedDocumentFooterId,"document-footer":_vm.selectedDocumentFooter,"document-footer-type":_vm.selectedDocumentFooterType,"page-key":_vm.pageSortKey,"update-mode":_vm.updateSortMode},on:{"update:dialog":function($event){_vm.editSortDialog=$event},"dialog-closed":_vm.editSortDialogClosed,"update-done":_vm.documentFooterSortingUpdated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }